var app;
(function (app) {
    var core;
    (function (core) {
        var cacheImages;
        (function (cacheImages) {
            'use strict';
            var CacheImages = /** @class */ (function () {
                /* @ngInject */
                CacheImages.$inject = ["$rootScope"];
                function CacheImages($rootScope) {
                    this.$rootScope = $rootScope;
                }
                CacheImages.prototype.cacheIt = function (preloadImages) {
                    if (preloadImages === void 0) { preloadImages = []; }
                    var _t = this;
                    if (_.isArray(preloadImages) == false) {
                        preloadImages = [];
                    }
                    console.log('preloadImages.length', preloadImages.length);
                    var list = preloadImages;
                    // return;
                    for (var i = 0; i < list.length; i++) {
                        var img = new Image();
                        img.onload = function () {
                            var index = list.indexOf(this);
                            if (index !== -1) {
                                // remove image from the array once it's loaded
                                // for memory consumption reasons
                                list.splice(index, 1);
                            }
                        };
                        // preloadImages.push(img);         
                        img.src = _t.$rootScope.IPATH + "/" + list[i];
                        console.log('image preloaded', img.src);
                    }
                };
                return CacheImages;
            }());
            cacheImages.CacheImages = CacheImages;
            angular
                .module('app.core.cacheImages', []);
            angular
                .module('app.core.cacheImages')
                .service('cacheImages', CacheImages);
        })(cacheImages = core.cacheImages || (core.cacheImages = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
